// constant for web page info
import bscNetworkConstants from './bscNetwork'
import polygonNetworkConstants from './polygonNetwork'
import arbitrumNetworkConstants from './arbitrumNetwork'
import ethNetworkConstants from './ethNetwork'
import avalancheNetworkConstants from './avalancheNetwork'
import optimismNetworkConstans from './optimismNetwork'

import { ChainIDThatSupport, GasSpeed } from '@/features/Web3Connector/types'
import { FindBestRateOptions, MethodNameForGetBestRate } from '@/types'

export const APP_NAME = 'WARDEN'
export const APP_VERSION = '3.4.3'
export const CYPRESS_TEST_TOKEN = '23cB8GsPLUqRg36jaz82htQRWigRy4EiBn8vG8qNgdsNXfFSwE'
export const WALLET_PROVIDER_LOCAL_STORAGE_KEY = 'current-wallet-connector'
export const NETWORK_ID_LOCAL_STORAGE_KEY = 'current-network-id'
export const DEFAULT_NETWORK_ID = ChainIDThatSupport.bsc
export const WARDEN_TOKEN_IMAGE = 'https://image.wardenswap.finance/WAD-Token.png'
export const CLOUDFLARE_RESIZE_IMAGE_URL = 'https://wardenswap.finance/cdn-cgi/image'
export const MWAD_NFT_IMAGE_S3_URL = 'https://image.wardenswap.finance/nft'
export const WARDEN_TITLE_WAB_PAGE = 'WardenSwap The Best Price with built-in Machine Learning.'
export const WARDEN_API_URL = 'https://api.wardenswap.finance/v1/api'
export const DEFAULT_GAS_LIMIT_FOR_READ_METHOD = '800000000'
export const DEFAULT_PRICE_SLIPPAGE = '1.5' // 1.5%
export const MAX_PRICE_IMPACT = '5' // 5%
export const USD_AMOUNT_FOR_CALC_TOKEN_PRICE = 100 // $100
export const PARTNER_ID = '0'
export const DEFAULT_GAS_SPEED = GasSpeed.AVERAGE
export const DEFAULT_AMPLITUDE_API_KEY = '2526ddc1c61e185eba0bc852bc294c67' // Key for production
export const DEFAULT_FIND_BEST_RATE_OPIONS: FindBestRateOptions = {
  shouldFindBestRateType: [MethodNameForGetBestRate.STRATEGIES, MethodNameForGetBestRate.SPLIT]
}

// constant separated by network
export const NETWORK_CONSTANT = {
  [ChainIDThatSupport.ethereum]: ethNetworkConstants,
  [ChainIDThatSupport.bsc]: bscNetworkConstants,
  [ChainIDThatSupport.polygon]: polygonNetworkConstants,
  [ChainIDThatSupport.arbitrum]: arbitrumNetworkConstants,
  [ChainIDThatSupport.avalanche]: avalancheNetworkConstants,
  [ChainIDThatSupport.optimism]: optimismNetworkConstans
}
